import React from 'react';
import { IGameItem } from './interfaces/IGameItem';
import './characterPage.css';
import canNextImg from './assets/sipka.png';
import nextWrong from './assets/wrong.png';
import Helpers from './helpers';
import { GameLevel, AppLang } from './Enums';
import { InfoDialog } from './InfoDialog';

import czFind from './assets/cz/p_postavu.png';
import enFind from './assets/eng/p_postavu.png';

const lj01 = require('./assets/hudba_01a.mp3');
const lj02 = require('./assets/hudba_02a.mp3');
const lj03 = require('./assets/hudba_03a.mp3');
const lj04 = require('./assets/hudba_04a.mp3');
const lj05 = require('./assets/hudba_05a.mp3');
const lj06 = require('./assets/hudba_06a.mp3');
const lj07 = require('./assets/hudba_07a.mp3');
const lj08 = require('./assets/hudba_08a.mp3');
const lj09 = require('./assets/hudba_09a.mp3');

const respYes = require('./assets/uspech.mp3');
const respNo = require('./assets/neuspech.mp3');


interface ICharacterPageProps {
    gameItems: IGameItem[];
    level: GameLevel;
    CurrentLang: AppLang;
    nextPage: (id: number) => void;
}

interface ICharacterPageStates {
    selectedCharacter: number;
    selectedText: number;
    isCharacterAudioPlaying: boolean;
    isTextAudioPlaying: boolean;
    isError: boolean;
    shuffled1: IGameItem[];
    shuffled2: IGameItem[];
    isInfoDialogShown: boolean;
}

export class CharacterPage extends React.Component<ICharacterPageProps, ICharacterPageStates> {

    private audioFile!: HTMLAudioElement;

    constructor(props: ICharacterPageProps) {
        super(props);
        const isInfoDialog = props.gameItems.filter(o => o.foundCharacter).length === 0;
        this.state = {
            selectedCharacter: 0,
            selectedText: 0,
            isCharacterAudioPlaying: false,
            isTextAudioPlaying: false,
            isError: false,
            shuffled1: Helpers.shuffleItems(this.props.gameItems),
            shuffled2: Helpers.shuffleItems(this.props.gameItems),
            isInfoDialogShown: isInfoDialog,
        };
//        this.audioFile = null;
    }

    render() {
        const isCz = this.props.CurrentLang === AppLang.Czech;
        const infoClass = 'poznate-postavu-dialog_' + (isCz ? 'cz' : 'en');
        const charItems = this.state.shuffled1.map((item, idx) => this.getCharacterItem(item, idx, item.id === this.state.selectedCharacter));
        const textItems = this.state.shuffled2.map((item, idx) => this.getTextItem(item, idx, item.id === this.state.selectedText));
        const infoDialog = this.state.isInfoDialogShown ? <InfoDialog cssClass={infoClass} onDialogClick={() => this.onInfoDialogClick()}/> : null;

        return (
            <div>
                <div>
                    <img src={isCz?czFind:enFind} alt="Snadný/Easy"/>
                </div>
                <div className="char-page">
                    <div className="characters">
                        <ul>
                            {charItems}
                        </ul>
                    </div>
                    <div className="texts">
                        <ul>
                            {textItems}
                        </ul>
                    </div>
                </div>
                {this.getCanNext()}
                {infoDialog}
            </div>
        )
    }

    getCanNext() {
        if (this.state.selectedCharacter && this.state.selectedText) {
            const imgName = this.state.isError ? nextWrong : canNextImg;
            return (
                <div className="can-next">
                    <img src={imgName} onClick={() => this.checkSelected()} alt="" />
                </div>
            )
        }
        return null;
    }

    getCharacterItem(item: IGameItem, idx: number, isSelected: boolean) {
        return (
            <li key={idx}>
                <div 
                    className={'menu-card-char char-0' + item.id + (item.foundCharacter ? 's' : '') + (isSelected ? ' activated' : '')}
                    onClick={() => this.characterSelected(item.id)}
                />
            </li>
        );
    }

    getTextItem(item: IGameItem, idx: number, isSelected: boolean) {
        const id = item.foundCharacter ? item.id + 's' : (this.props.level === GameLevel.Easy ? item.id : "0");
        return (
            <li key={idx}>
                <div 
                    className={'menu-card-text text-0' + id + (isSelected ? ' activated' : '')}
                    onClick={() => this.textSelected(item.id)}
                />
            </li>
        );
    }

    audioPlayStop(isCharacter: boolean, id: number) {
        const playedChar = this.state.isCharacterAudioPlaying;
        const playedText = this.state.isTextAudioPlaying;

        if (this.state.isCharacterAudioPlaying || this.state.isTextAudioPlaying) {
            this.stopAudio();
        }

        if ((isCharacter && this.state.selectedCharacter !== id) 
            || (isCharacter && !playedChar && this.state.selectedCharacter === id) 
            || (!isCharacter && this.state.selectedText !== id)
            || (!isCharacter && !playedText && this.state.selectedText === id)
            ) {
            this.playAudio(isCharacter, id);
        }
    }

    playAudio(isCharacter: boolean, id: number) {
        console.log("play audio", isCharacter, id);

        this.audioFile = new Audio(this.getAudio(isCharacter, id));
        this.audioFile.addEventListener('ended', () => this.audioFinish());
        this.audioFile.controls = true;
        this.audioFile.play();

        this.setState((state, props) => ({
            isCharacterAudioPlaying: isCharacter,
            isTextAudioPlaying: !isCharacter
        }));
    }

    stopAudio() {
        console.log("stop audio");
        this.audioFile.pause();
        this.setState((state, props) => ({
            isCharacterAudioPlaying: false,
            isTextAudioPlaying: false
        }));
    }

    audioFinish() {
        this.stopAudio();
    }

    getAudio(isCharacter: boolean, id: number) {
        switch (id) {
            case 1: return lj01;
            case 2: return lj02;
            case 3: return lj03;
            case 4: return lj04;
            case 5: return lj05;
            case 6: return lj06;
            case 7: return lj07;
            case 8: return lj08;
            case 9: return lj09;
            case -2: return respYes;
            case -3: return respNo;
        }
        return lj01;
    }

    checkSelected() {
        this.stopAudio();
        if (this.state.selectedCharacter === this.state.selectedText) {
            // this.playAudio(true, -2);
            this.props.nextPage(this.state.selectedCharacter);
        } else {
            this.playAudio(true, -3);
            this.setState((state, props) => ({
                isError: true
            }));
        }
    }

    characterSelected(id: number) {
        this.audioPlayStop(true, id);
        this.setState((state, props) => ({
            selectedCharacter: id,
            isError: false
        }));
    }

    textSelected(id: number) {
        this.audioPlayStop(false, id);
        this.setState((state, props) => ({
            selectedText: id,
            isError: false
        }));
    }

    onInfoDialogClick() {
        this.setState((state, props) => ({
            isInfoDialogShown: false
        }));
    }
}
