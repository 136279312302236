import React from 'react';
import { IGameItem } from "./interfaces/IGameItem";

interface IGameDeskItemProps {
    item: IGameItem
}

export class GameDeskItem extends React.Component<IGameDeskItemProps, {}> {
    render() {

        const stText  = 'menu-card-text2 text2-0' + (this.props.item.foundCharacter ? this.props.item.id : 0);
        const stDeska = 'menu-card-deska deska-0' + (this.props.item.foundOpera ? this.props.item.id : 0);

        return (
            <li>
                {/* <div className="images-row"> */}
                    <div className={'menu-card-char char-0' + this.props.item.id}/>
                    <div className={stText}/>
                    <div className={stDeska}/>
                    {/* <div className={'card-cell ' + this.props.item.operaPicture}/> */}
                {/* </div> */}
                {/* <div className="title-row">
                    {this.props.item.characterName}
                </div> */}
            </li>
        );
    }
}

// export function GameDeskItem(props: IGameItem) {
//     return (
//         <li>
//             <div className={props.characterPicture}/>
//             <div className={props.operaPicture}/>
//             {props.characterName}
//         </li>
//     )
// }
