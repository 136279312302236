import React from 'react';
import './header.css';
import { AppLang } from './Enums';

import czLeos from './assets/cz/leos.png';
import enLeos from './assets/eng/leos.png';
import czNejhr from './assets/cz/nejhranejsi.png';
import enNejhr from './assets/eng/nejhranejsi.png';


interface IHeaderProps {
    CurrentLang: AppLang;
}

export class Header extends React.Component<IHeaderProps, {}> {
    render() {
        const isCz = this.props.CurrentLang === AppLang.Czech;

        return (
            <div className="header">
                <div>
                    <img src={isCz?czLeos:enLeos} alt="Leoš Janáček"/>
                </div>
                <div>
                    <img src={isCz?czNejhr:enNejhr} alt="Leoš Janáček"/>
                </div>
            </div>
        );
    }
}
