import React from 'react';
import { IGameItem } from './interfaces/IGameItem';
import { GameDesk } from './GameDesk';
import { CharacterPage } from './CharacterPage';
import { OperaPage } from './OperaPage';
import { GameLevel, AppLang } from './Enums';

import czBack from './assets/cz/zpet.png';
import enBack from './assets/eng/zpet.png';
import { Link } from 'react-router-dom';

interface IGamePageProps {
    level: GameLevel;
    CurrentLang: AppLang;
} 

interface IGamePageState {
    gameItems: IGameItem[];
    currentPage: number;
    currentId: number;
}

export class GamePage extends React.Component<IGamePageProps, IGamePageState> {

    private maxItems = 9;

    constructor(props: any) {
        super(props);

        this.state = {
            gameItems: this.getInitialItems(),
            currentPage: 1,
            currentId: 0
        }
    }


    render() {
        const isCz = this.props.CurrentLang === AppLang.Czech;
        let backDiv = null;
        // let backDiv = (
        //     <div className="game-back" onClick={() => this.onBackPageClick()}>
        //         <img src={isCz?czBack:enBack} alt="Zpět/Back"/>
        //     </div>
        // );
        if (this.state.currentPage===1) {
            backDiv = (
              <Link to="">
                <div className="game-back">
                    <img src={isCz?czBack:enBack} alt="Zpět/Back"/>
                </div>
              </Link>
            );
        }

        return (
            <div className="game-page">
                {this.getCurrentPage()}
                {backDiv}
            </div>
        );
    }

    getCurrentPage() {
        switch (this.state.currentPage) {
            case 1:
                return (<GameDesk gameItems={this.state.gameItems} level={this.props.level} CurrentLang={this.props.CurrentLang} 
                    nextPage={() => this.onMenuPageNext()}/>);
            case 2:
                return (<CharacterPage gameItems={this.state.gameItems} level={this.props.level} CurrentLang={this.props.CurrentLang} 
                    nextPage={(id) => this.onCharacterPageNext(id)}/>);
            case 3:
                return (<OperaPage gameItems={this.state.gameItems} level={this.props.level} CurrentLang={this.props.CurrentLang}
                     currentId={this.state.currentId} nextPage={(id) => this.onOperaPageNext(id)}/>);
            default:
                return null;
        }
    }

    getInitialItems() : IGameItem[] {
        const items: IGameItem[] = [];

        for (let i = 1; i <= this.maxItems; i++) {
            items.push({
                id : i,
                foundCharacter: false,
                foundOpera: false,
                // foundCharacter: true,
                // foundOpera: true,
                // foundCharacter: i<this.maxItems,
                // foundOpera: i<this.maxItems,
            })
        }

        return items;
    }

    onMenuPageNext() {
        console.log("Start");
        this.goPage(2);
    }

    onCharacterPageNext(id: number) {
        console.log("Nasel jsem postavu", id);
        this.foundItem(id, true);
        this.setState((state, props) => ({
            currentId: id
        }));
        this.goPage(3);
    }

    onOperaPageNext(id: number) {
        console.log("Nasel jsem operu", id);
        this.foundItem(id, false);
        this.goPage(1);
    }

    onBackPageClick() {
        if (this.state.currentPage === 1) {

        } else {
            this.goPage(this.state.currentPage-1);
        }
    }

    goPage(pageNumber: number) {
        this.setState((state, props) => ({
            currentPage: pageNumber
        }));
    }

    foundItem(id: number, isCharacter: boolean) {
        const items = [...this.state.gameItems];
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === id) {
                if (isCharacter) {
                    items[i].foundCharacter = true;
                } else {
                    items[i].foundOpera = true;
                }
                break;
            }
        }
        this.setState((state, props) => ({
            gameItems: items
        }));
    }
}
