import React from 'react';
import './footer.css';
import janacek_logo from './assets/janacek_brno_logo.jpg';
import brno_logo from './assets/logo_brno.jpg';
import tic_logo from './assets/tic_logo.jpg';
import goto_logo from './assets/4-go-to-brno.png';
import supra_logo from './assets/Supraphonline_logo_black.png';
 
export class Footer extends React.Component<{}, {}> {
    render() {

        return (
            <div className="footer centre">
                <div className="wrapper">
                    <a href="https://ticbrno.cz/" target="_blank">
                        <img src={janacek_logo} alt=""/>    
                    </a>

                    <div className="logos">
                        <div className="imgs">
                            <a href="https://www.brno.cz" target="_blank">
                                <img src={brno_logo} alt=""/>
                            </a>
                            <a href="https://www.gotobrno.cz/" target="_blank">
                                <img src={goto_logo} alt=""/>
                            </a>
                            <a href="https://ticbrno.cz/" target="_blank">
                                <img src={tic_logo} alt=""/>
                            </a>
                            <a href="https://www.supraphonline.cz/" target="_blank">
                                <img src={supra_logo} alt=""/>
                            </a>
                        </div>
                        {/* <div>
                            <img src={goto_logo} alt=""/>
                            <img src={supra_logo} alt=""/>
                        </div> */}
                        <div className="leos-web">
                            <a href="https://www.leosjanacek.eu" target="_blank">www.leosjanacek.eu</a>
                        </div>
                        <div>Statutární město Brno podporuje TIC BRNO, příspěvkovou organizaci</div>
                        <div>©2020 - Vendula Chalánková, Robert Knebl, Daniel Knebl</div>

                        {/* <div>
                            <img src={brno_logo} alt=""/>
                            <img src={tic_logo} alt=""/>
                        </div>
                         <div>
                            <img src={goto_logo} alt=""/>
                            <img src={supra_logo} alt=""/>
                         </div> */}
                    </div>
                </div>
            </div>
        );
    }
}
