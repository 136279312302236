import React from 'react';
import './infodialog.css';

interface IInfoDialogProps {
    cssClass: string;
    onDialogClick: () => void;
}

export class InfoDialog extends React.Component<IInfoDialogProps, {}> {
    render() {
        return (
            <div className="info-dialog" onClick={() => this.props.onDialogClick()}>
                    <div className={'info-dialog-content ' + this.props.cssClass}>
                        {this.props.children}
                    </div>
            </div>
        );
    }
}
