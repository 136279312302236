import React from 'react';
import { IGameItem } from './interfaces/IGameItem';
import { GameDeskItem } from './GameDeskItem';
import { AppLang, GameLevel } from './Enums';
import { InfoDialog } from './InfoDialog';
import './gamedesk.css';

import bravoImg from './assets/bravo.png';
import czFind from './assets/cz/poznate_opery.png';
import enFind from './assets/eng/poznate_opery.png';
import czNext from './assets/cz/bude_to_tez.png';
import enNext from './assets/eng/bude_to_tez.png';
import czBack from './assets/cz/zpet.png';
import enBack from './assets/eng/zpet.png';
import { Link } from 'react-router-dom';

const applause = require('./assets/applause.mp3'); 

interface IGameDeskProps {
    gameItems: IGameItem[];
    level: GameLevel;
    CurrentLang: AppLang;
    nextPage: () => void;
}

interface IGameDeskState {
    isBravo: boolean;
}

export class GameDesk extends React.Component<IGameDeskProps, IGameDeskState> {

    private audioFile!: HTMLAudioElement;

    constructor(props: IGameDeskProps) {
        super(props);

        this.state= {
            isBravo: this.isFoundAll()
        }
    }

    render() {
        const isCz = this.props.CurrentLang === AppLang.Czech;
        const items = this.props.gameItems.map((item, idx) => <GameDeskItem key={idx} item={item} />)
        let bravoComp = null;
        let deskClass = "game-desk";
        if (this.state.isBravo) {
            bravoComp = this.getBravoDiv();
            this.playAudio(applause);
        }
        if (this.isFoundAll()) {
            deskClass += " foundall";
        }

        return (
            <div>
                <div onClick={() => this.onClickNext()}>
                    <div>
                        <img src={isCz?czFind:enFind} alt="Poznáte jeho opery?/Can you name his operas?"/>
                    </div>
                    <div className={deskClass}>
                        <ul>
                            {items}
                        </ul>
                    </div>
                </div>

                {bravoComp}
            </div>
        )
    }

    onClickNext() {
        if (!this.isFoundAll()) {
            this.props.nextPage();
        }
    }

    isFoundAll(): boolean {
        return this.props.gameItems.filter(o => !o.foundCharacter).length === 0;
    }

    getBravoDiv() {
        const isCz = this.props.CurrentLang === AppLang.Czech;

        let nextDiv = null;
        if (this.props.level === GameLevel.Easy) {
            nextDiv = (
                <div>
                    <a href={isCz?'pokrocily':'advance'}>
                        <img src={isCz?czNext:enNext} alt="Bravo"/>
                    </a>
                </div>
            );
        }


        return (
            <InfoDialog cssClass='' onDialogClick={() => this.onInfoDialogClick()}>
                <div className="bravo-dialog">
                    <div className="bravo-img">
                        <img src={bravoImg} alt="Bravo"/>
                    </div>
                    {nextDiv}
                    <Link to="">
                        <div className="bravo-back">
                            <img src={isCz?czBack:enBack} alt="Zpět/Back"/>
                        </div>
                    </Link>
                </div>
            </InfoDialog>
        );
    }

    playAudio(audio: any) {
        this.audioFile = new Audio(audio);
        this.audioFile.addEventListener('ended', () => this.stopAudio());
        this.audioFile.controls = true;
        this.audioFile.play();
    }

    stopAudio() {
        this.audioFile.pause();
    }

    onInfoDialogClick() {
        this.setState((state, props) => ({
            isBravo: false
        }));
    }
}
