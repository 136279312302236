import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
//  Link
} from "react-router-dom";
import { GamePage } from './GamePage';
import { GameLevel, AppLang } from './Enums';
import { Header } from './Header';
import { Footer } from './Footer';
import { Menu } from './Menu';


interface IAppState {
  lang: AppLang;
}

class App extends React.Component<{}, IAppState> { 

  constructor(props: any) {
    super(props);
    this.state = {
      lang: AppLang.Czech
    }
  }

//const App: React.FC = () => {
  //  <Router basename={'/lj'}> 
  render() {
    return (

      <Router> 

        <div className="app">
          <Header CurrentLang={this.state.lang}/>

          <Switch>
            <Route path="/snadny" render={() => this.getGame(GameLevel.Easy, AppLang.Czech)} /> 
            <Route path="/pokrocily" render={() => this.getGame(GameLevel.Advance, AppLang.Czech)} /> 
            <Route path="/lj/snadny" render={() => this.getGame(GameLevel.Easy, AppLang.Czech)} /> 
            <Route path="/lj/pokrocily" render={() => this.getGame(GameLevel.Advance, AppLang.Czech)} /> 

            <Route path="/easy" render={() => this.getGame(GameLevel.Easy, AppLang.English)} /> 
            <Route path="/advance" render={() => this.getGame(GameLevel.Advance, AppLang.English)} /> 
            <Route path="/lj/easy" render={() => this.getGame(GameLevel.Easy, AppLang.English)} /> 
            <Route path="/lj/advance" render={() => this.getGame(GameLevel.Advance, AppLang.English)} /> 

            <Route path="/">
              <Menu CurrentLang={this.state.lang} 
                    LangChanged={(lang) => this.onLangChanged(lang)}
                    LevelClicked={(level) => this.onLevelSelected(level)}
              />
            </Route>
          </Switch>

          <Footer/>                
        </div>

      </Router>


    );
  }

  onLangChanged(lang: AppLang) {
    let title = 'Leoš Janáček - (Po)znáte jeho opery?';
    if (lang === AppLang.English) {
      title = 'Leoš Janáček - Can you name his operas?';
    }
    document.title = title;
    this.setState((state, props) => ({
      lang
    }));
  }

  onLevelSelected(level: GameLevel) {
    // const pr2: any = this.props;
    // console.log('props = ', this.props);
    // pr2.history.push('/snadna');
    // this.props.history.push('/snadna');
  }
  
  getGame(level: GameLevel, lang: AppLang) {
    if (this.state.lang !== lang) {
      this.onLangChanged(lang);
    }
  
    return (
        <GamePage level={level} CurrentLang={lang}/>
    );
  }
}


export default App;
