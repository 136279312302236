import React from 'react';
import './operaPage.css';
import { IGameItem } from './interfaces/IGameItem';
import canNextImg from './assets/sipka.png';
import nextWrong from './assets/wrong.png';
import Helpers from './helpers';
import { GameLevel, AppLang } from './Enums';
import { InfoDialog } from './InfoDialog';

import czFind from './assets/cz/spoj_desku.png';
import enFind from './assets/eng/spoj_desku.png';

const applause = require('./assets/applause.mp3'); 
const respYes = require('./assets/uspech.mp3');
const respNo = require('./assets/neuspech.mp3');

const lj01 = require('./assets/hudba_01b.mp3');
const lj02 = require('./assets/hudba_02b.mp3');
const lj03 = require('./assets/hudba_03b.mp3');
const lj04 = require('./assets/hudba_04b.mp3');
const lj05 = require('./assets/hudba_05b.mp3');
const lj06 = require('./assets/hudba_06b.mp3');
const lj07 = require('./assets/hudba_07b.mp3');
const lj08 = require('./assets/hudba_08b.mp3');
const lj09 = require('./assets/hudba_09b.mp3');

interface IOperaPageProps {
    gameItems: IGameItem[];
    currentId: number;
    level: GameLevel;
    CurrentLang: AppLang;
    nextPage: (id: number) => void;
}

interface IOperaPageStates {
    selectedCharacter: number;
    selectedOpera: number;
    isCharacterAudioPlaying: boolean;
    isOperaAudioPlaying: boolean;
    isError: boolean;
    shuffled: IGameItem[];
    isInfoDialogShown: boolean;
    isOperaSelectedShown: boolean;
}

export class OperaPage extends React.Component<IOperaPageProps, IOperaPageStates> {

    private audioFile!: HTMLAudioElement;

    constructor(props: IOperaPageProps) {
        super(props);
        const isInfoDialog = props.gameItems.filter(o => o.foundOpera).length === 0;
        this.state = {
            selectedCharacter: 0,
            selectedOpera: 0,
            isCharacterAudioPlaying: false,
            isOperaAudioPlaying: false,
            isError: false,
            shuffled: Helpers.shuffleItems(this.props.gameItems),
            isInfoDialogShown: isInfoDialog,
            isOperaSelectedShown: false,
        }
    }

    render() {
        const isCz = this.props.CurrentLang === AppLang.Czech;
        let infoClass = 'poznate-operu-dialog_' + (isCz ? 'cz' : 'en');
        if (this.state.isOperaSelectedShown) {
            infoClass = 'opera-card-deska deska-01';
        }
        const items = this.state.shuffled.map((item, idx) => this.getItem(item, idx, item.id === this.state.selectedOpera));
        let infoDialog = null;
        if (this.state.isOperaSelectedShown) {
            infoDialog = this.getWinnerDialog();
        } else if (this.state.isInfoDialogShown) {
            infoDialog = <InfoDialog cssClass={infoClass} onDialogClick={() => this.onInfoDialogClick()}/>;
        }

        return (
            <div>
                <div>
                    <img src={isCz?czFind:enFind} alt="Snadný/Easy"/>
                </div>
                <div className="opera-page">
                    <div className="menu-card">
                        <div className={"menu-card-char char-0"   + this.props.currentId + (this.props.currentId === this.state.selectedCharacter ? " activated" : "")}
                            onClick={() => this.characterSelected(this.props.currentId)}
                        >
                        </div>
                        <div className={'menu-card-text text-0' + this.props.currentId}/>
                    </div>

                    <div className="operas">
                        <ul>
                            {items}
                        </ul>
                    </div>
                </div>
                {this.getCanNext()}
                {infoDialog}
            </div>
        )
    }

    getCanNext() {
        if (this.state.selectedCharacter && this.state.selectedOpera && !this.state.isOperaSelectedShown) {
            const imgName = this.state.isError ? nextWrong : canNextImg;
            return (
                <div className="can-next">
                    <img src={imgName} onClick={() => this.checkSelected()} alt="" />
                </div>
            )
        }
        return null;
    }

    getItem(item: IGameItem, idx: number, isSelected: boolean) {
        const id = item.foundOpera ? item.id + 's' : (this.props.level === GameLevel.Easy ? item.id : "0");
        return (
            <li key={idx}>
                <div 
                    className={'opera-card-deska deska-0' + id + (isSelected ? ' activated' : '')}
                    onClick={() => this.operaSelected(item.id)}
                />
            </li>
        );
    }

    audioPlayStop(isCharacter: boolean, id: number) {
        const playedChar = this.state.isCharacterAudioPlaying;
        const playedText = this.state.isOperaAudioPlaying;

        if (this.state.isCharacterAudioPlaying || this.state.isOperaAudioPlaying) {
            this.stopAudio();
        }

        if ((isCharacter && this.state.selectedCharacter !== id) 
            || (isCharacter && !playedChar && this.state.selectedCharacter === id) 
            || (!isCharacter && this.state.selectedOpera !== id)
            || (!isCharacter && !playedText && this.state.selectedOpera === id)
            ) {
            this.playAudio(isCharacter, id);
        }
    }

    playAudio(isCharacter: boolean, id: number) {
        console.log("play audio", isCharacter, id);

        this.audioFile = new Audio(this.getAudio(isCharacter, id));
        this.audioFile.addEventListener('ended', () => this.audioFinish());
        this.audioFile.controls = true;
        this.audioFile.play();

        this.setState((state, props) => ({
            isCharacterAudioPlaying: isCharacter,
            isOperaAudioPlaying: !isCharacter
        }));
    }

    stopAudio() {
        console.log("stop audio");
        this.audioFile.pause();
        this.setState((state, props) => ({
            isCharacterAudioPlaying: false,
            isOperaAudioPlaying: false
        }));
    }

    audioFinish() {
        this.stopAudio();
    }

    getAudio(isCharacter: boolean, id: number) {
        switch (id) {
            case 1: return lj01;
            case 2: return lj02;
            case 3: return lj03;
            case 4: return lj04;
            case 5: return lj05;
            case 6: return lj06;
            case 7: return lj07;
            case 8: return lj08;
            case 9: return lj09;
            case -1: return applause;
            case -2: return respYes;
            case -3: return respNo;
        }
        return lj01;
    }

    goToNextPage() {
        this.props.nextPage(this.state.selectedCharacter);
    }

    checkSelected() {
        console.log('checkSelected');
        this.stopAudio();
        if (this.state.selectedCharacter === this.state.selectedOpera) {
            this.playAudio(true, -1);
            // if (this.props.level === GameLevel.Easy) {
            //     this.goToNextPage();
            // } else {
                this.setState((state, props) => ({
                    isOperaSelectedShown: true
                }));
            // }
        } else {
            this.playAudio(true, -3);
            this.setState((state, props) => ({
                isError: true
            }));
        }
    }

    getWinnerDialog() {
        return (
        <InfoDialog cssClass='' onDialogClick={() => this.onInfoDialogClick()}>
            <div className='winner-info'>
                <div>
                    <div className="col">
                        <div className={"menu-card-char char-0" + this.props.currentId}></div>
                    </div>
                    <div className="col">
                        <div className={'opera-deska deska-0' + this.props.currentId}></div>
                    </div>
                </div>
                <div>
                    <div className="name-col">
                        <div className={'menu-card-text text-0' + this.props.currentId}/>
                    </div>
                </div>
            </div>
        </InfoDialog>
        );
    }

    characterSelected(id: number) {
        this.audioPlayStop(true, id);
        this.setState((state, props) => ({
            selectedCharacter: id,
            isError: false
        }));
    }

    operaSelected(id: number) {
        this.audioPlayStop(false, id);
        this.setState((state, props) => ({
            selectedOpera: id,
            isError: false
        }));
    }

    onInfoDialogClick() {
        if (this.state.isOperaSelectedShown) {
            this.goToNextPage();
        } else {
            this.setState((state, props) => ({
                isInfoDialogShown: false
            }));
        }
    }
}
