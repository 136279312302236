import React from 'react';
import { AppLang } from './Enums';
import { GameLevel } from './Enums';
import { Link } from 'react-router-dom';
import './menu.css';

import czCz from './assets/cz/cesky.png';
import enEn from './assets/eng/english.png';
import czSelect from './assets/cz/obtiznost.png';
import enSelect from './assets/eng/obtiznost.png';
import czLevel1 from './assets/cz/lehka.png';
import enLevel1 from './assets/eng/lehka.png';
import czLevel2 from './assets/cz/pokrocily.png';
import enLevel2 from './assets/eng/pokrocily.png';
import czFind from './assets/cz/poznate_opery.png';
import enFind from './assets/eng/poznate_opery.png';



interface IMenuProps {
    CurrentLang: AppLang;
    LangChanged: (lang: AppLang) => void;
    LevelClicked: (level: GameLevel) => void;
}

export class Menu extends React.Component<IMenuProps, {}> {
    render() {
        const isCz = this.props.CurrentLang === AppLang.Czech;

        return (
            <div>
                <div className="header2">
                    <img src={isCz?czFind:enFind} alt="Poznáte jeho opery?/Can you name his operas?"/>
                </div>
                <div className="menu">
                    <ul>
                        <li className={(isCz?'active':'')} onClick={() => this.props.LangChanged(AppLang.Czech)}>
                            <img src={czCz} alt="Česky/Czech"/>
                        </li>
                        <li className={(!isCz?'active':'')} onClick={() => this.props.LangChanged(AppLang.English)}>
                            <img src={enEn} alt="Anglicky/English"/>
                        </li>
                    </ul>

                    <div className="select-diff">
                        <img src={isCz?czSelect:enSelect} alt="Vyber obtížnost/Select difficulty"/>
                    </div>

                    <ul>
                        <li>
                            <Link to={isCz?'snadny':'easy'}>
                                <img src={isCz?czLevel1:enLevel1} alt="Snadný/Easy"/>
                            </Link>
                        </li>
                        <li>
                            <Link to={isCz?'pokrocily':'advance'}>
                                <img src={isCz?czLevel2:enLevel2} alt="Pokročilý/Advance"/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
